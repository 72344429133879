<template>
<div>
  <v-dialog v-model="mediaDialog" fullscreen>
    <test-media 
      v-if="mediaDialog" 
      :stream="stream" 
      :media-type="mediaType" 
      :toggle-proctor="toggleProctor"
      @close="mediaDialog=false" 
      @answerUploaded="answerUploaded" 
      ref="mediaDialog">
    </test-media>
      <!-- :tlh="timeLeftHtml"  -->
  </v-dialog>
  <v-card style="border-radius: 10px">
    <v-card-text class="OSX" style="min-height: 85vh">
        <div v-if="question.extra"><v-btn @click="compDialog=true" color="info darken-1" outline flat round>Comprehension</v-btn></div>
         <splitpanes horizontal style="height: calc(100vh - 64px)">
            <pane :min-size="testConfig.MIN_PANE_SIZE || 20">
              <!-- <PDFDocument :url="question.pdf" :scale="1"></PDFDocument> -->
              <v-btn v-if="showDownload" :href="question.pdf" target="_blank" outline>Download Question Paper</v-btn>
              <!-- <img v-for="(image, iid) in question.pdf_images" :key="iid" :src="image" style="display:block; margin: 0 auto"/> -->
              
              <vue-load-image v-for="(image, iid) in question.pdf_images" :key="iid">
                <img slot="image" :src="image" style="display:block; margin: 0 auto"/>
                <v-progress-circular
                  slot="preloader"
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                  style="margin: 0 auto; display: block; min-height: 200px"
                ></v-progress-circular>
                <div slot="error">loading failed</div>
              </vue-load-image>
                <!-- <PdfViewer v-if="question.pdf" :docPath="question.pdf" /> -->
            </pane>
            <pane>
              <div v-if="question.type==8">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="question.ua"
                  autocomplete="off"
                  :options="editorOption"
                  :disabled="readonly" 
                  :readonly="readonly" 
                  data-gramm="false"
                  data-enable-grammarly="false"
                  spellcheck="false"
                  />
              </div>
              <div v-if="question.type==8 && !readonly" class="pt-1">
                <v-btn v-if="showAudioRecording" color="primary" outline @click="onCaptureClick(1)"> <v-icon dark >mic</v-icon> &nbsp;Record Audio</v-btn>
                <v-btn color="primary" outline @click="onCaptureClick(2)"> <v-icon dark >camera_alt</v-icon> &nbsp;Capture Image ({{question.iu.length || 0}})</v-btn>
                <!--v-if="showImageCapture" -->
                <!-- <v-btn color="primary" :disabled="!audioRecording" @click="stopRecordingAudio()" >Stop</v-btn> -->
                <v-btn v-if="showAttachmentButton" @click="$emit('showAttachmentDialog')"><v-icon>attachment</v-icon> Show Attachments</v-btn>

              </div>
              <image-grid v-if="question.iu && question.iu.length" :images="question.iu" :hideActions="readonly" @answerDeleted="(data) => $emit('answerDeleted', data)"></image-grid>
              <audio-list v-if="question.vu && question.vu.length" :audios="question.vu" :hideActions="readonly" @answerDeleted="(data) => $emit('answerDeleted', data)"></audio-list>

              <div class="choices" v-if="choices.indexOf(question.type)>-1">
                
                <v-radio-group  column v-if="question.type==3||question.type==7" v-model="question.ua" >
                  <v-radio v-for="(cl,ci) in choiceLabels" :key="cl"  :value="ci" :disabled="disabled"  :readonly="readonly" color="primary">
                    <div slot="label" v-html="cl+'. '+question[cl]" class="black--text"></div>
                  </v-radio>
                </v-radio-group>

                <div v-if="question.type==4">
                  <v-checkbox v-for="(cl,ci) in choiceLabels" :key="cl" :value="ci" :disabled="disabled" :readonly="readonly" color="primary" v-model="question.ua">
                    <div slot="label" v-html="cl+'. '+question[cl]"></div>
                  </v-checkbox>
                </div>
                
                <div v-if="question.type==6" >
                  <br><br>
                  <div> 
                    <v-flex xs12 d-flex mt-6 style="max-width: 400px; flex-wrap: wrap;"> 
                      <v-text-field  v-model="question.ua" readonly :disabled="disabled" ></v-text-field>
                      <v-btn round outline @click="backspace(question)" color="info" block :readonly="readonly" :disabled="disabled||readonly">Backspace</v-btn>
                      <v-btn round outline v-for="x in [7,8,9,4,5,6,1,2,3,0,'.','-']" :key="x" @click="answerInteger(question,isNaN(x)?x:x)" :color="'info'" :disabled="disabled||readonly">{{isNaN(x)?x:x}}</v-btn>
                      <v-btn round outline @click="clearAll(question)" color="info" block :readonly="readonly" :disabled="disabled||readonly">Clear All</v-btn>
                    </v-flex>
                  </div>
                </div>
              </div>
            </pane>
          </splitpanes>
        
    </v-card-text>
  </v-card>

  <v-dialog v-model="compDialog" fullscreen>
    <v-card>
      <v-card-text v-html="question.extra"></v-card-text>
      <v-card-actions class="mt-2 text-xs-center">
          <v-btn color="info darken-1" outline flat round @click="compDialog=null">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import PDFJS from './../../components/PDFJS.vue'
import {renderMathJax} from './../../utils.js'
import {mapActions} from 'vuex'
import VueLoadImage from 'vue-load-image'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
 
//  import Zooming from 'zooming'

import TestMedia from './TestQuestionMedia'
import AudioList from './../../components/AudioList'
import ImageGrid from './../../components/ImageGrid'

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import testConfig from './utils/readEnv'

import { quillEditor } from 'vue-quill-editor'
var toolbarOptions = [
  ['bold', 'italic', 'underline',],        // toggled buttons 
  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'direction': 'rtl' }],                         // text direction
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['clean']                                         // remove formatting button
];


var updateTextTimer = null

export default{
	data(){
    return {
      question: {},
      choices: [3,4,6,7],
      choiceLabels: ['A','B','C','D'],
      menuProps: {},
      editorOption: {
        modules: {
          toolbar: toolbarOptions
        }
      },
      mediaDialog: false,
      mediaType: 1,
      // localTypedAnswer: '',
      compDialog: null,
      testConfig
    }
  },
  mounted(){
    this.resetMenuProps()
    this.question = Object.assign({}, this._question)
    if(this.question.type==4 && this.question.ua==null) this.$set(this.question,'ua',[])
    // if(this.question.type==8) this.localTypedAnswer = this.question.ua?this.question.ua+'':''
    renderMathJax(this)
    setTimeout(() => {
      this.renderPDF()
    },1000)
    
  },
	methods: {
    ...mapActions(['updateQuestion']),
    answerUploaded(data) {
      this.question[data.type] = data.data
      this.updateQuestion({ question: { [data.type]: data.data, ats: new Date() } })
      this.$emit('initStream')
      this.mediaDialog = false
    },
    //Integer pad
    backspace(question) {
      if(question.ua && question.ua.length>0) question.ua = question.ua.substring(0,question.ua.length-1)
    },
    answerInteger(question, val) {
      if(val === '-'){
        if(question.ua!=null && question.ua.indexOf('-')>-1) { 
          console.log(question.ua.split('-').join(''))
          return question.ua=question.ua.split('-').join(''); 
        }
        else {
          return question.ua = '-'+(question.ua || '');
        }
      }
      if(val==='.' && question.ua && question.ua.indexOf('.')>-1) return
      if(question.ua===null || question.ua===undefined) question.ua=''
      question.ua = question.ua.concat(val)
    },
    clearAll(question) {
      question.ua = question.type==4?[]:null
    },
    resetMenuProps(){
      this.menuProps = { 'z-index': 206 }
    },
    clearLocalTypedAnswer(){
      this.question.ua = ''
    },
    hideIfNoFileCaptured(){
      this.$refs.mediaDialog.hideIfNoFileCaptured()
    },
    onCaptureClick(type){
      this.mediaDialog=true;
      this.mediaType=type
    },
    renderPDF() {
    },
    makeFullScreen(ctx) {
      console.log(ctx)
    }
	},
	props:{
    '_question': Object,
    'disabled': {
      type: Boolean,
      default: false
    },
    'ronly': {
      type: Boolean,
      default: false
    },
    'hidebuttons': {
      type: Boolean,
      default: false
    },
    'stream': {
      type: MediaStream,
      default: () => null
    },
    'toggleProctor': {
      type: Function,
      default: () => {}
    },
    'showAttachmentButton': {
      type: Boolean,
      default: false
    },
    'showAttachments': {
      type: Boolean,
      default: false
    }
	},
  watch: {
    'this.submitdialog'(val){
      if(val) this.menuProps['z-index'] = 8
      else this.resetMenuProps()
    },
    'question.ua':{
      deep: true,
      handler(answer, oldanswer){
        if(answer!==oldanswer)
        // this.$emit('answer', {qn: this.question.qn, answer})
        this.updateQuestion({ question: { ua: answer, ats: new Date() } })
      }
    },
    '_question':{
      deep: true,
      handler(value){
        this.question = value
      }
    },
    'localTypedAnswer'(val) {
      if(updateTextTimer!==null) clearTimeout(updateTextTimer)
      updateTextTimer = setTimeout(() => {
        this.$emit('answer', {qn: this.question.qn, answer: val})
      },100)
    }
  },
  components: {
    quillEditor,
    'test-media': TestMedia,
    'image-grid': ImageGrid,
    'audio-list': AudioList,
    'vue-load-image': VueLoadImage,
    Splitpanes, Pane
  },
  computed: {
    readonly(){
      return !!this.testConfig.ONLY_PDF_ANSWER || this.ronly
    },
    showDownload(){
      return this.testConfig.QUESTION_PDF_DOWNLOAD==true
    },
    showAudioRecording(){
      return this.testConfig.AUDIO_RECORDING
    },
    showImageCapture(){
      return this.testConfig.IMAGE_CAPTURE
    }
  }
}
</script>
<style>
.ql-editor{
  min-height: 100px;
}
</style>
<style scoped>

.v-progress-circular{
  margin-top: 4rem
}

.v-card__actions{
  justify-content: space-between;
}

.question-action-progress{
  margin-top: 0
}
label{
  color: black
}
img{
  max-width: 100%;
}
.splitter-pane-resizer.horizontal{
  height: 20px !important;
  background: red !important;
  margin: -10px 0 !important;
}
.splitpanes__pane{
  overflow: auto !important;
}


</style>