<template>
<div class="text-xs-center">
    <h3 class="display-2">Test Submitted</h3>
    <div class="p4">
     <img src="./../../assets/result.png" alt="Result" style="width: 300px;max-width:100%">
    </div>
    <br>
    <v-alert :value="true"  :color="color">{{reason?reason:'Your test has been submitted successfully.'}}</v-alert>
    <v-btn :to="actionUrl">{{actionText}}</v-btn>
</div>
</template>
<script>
var redirector = null
import {mapGetters} from 'vuex'
export default {
  data(){
    return {
      reason: null,
      color: 'success'
    }
  },
  beforeDestroy(){
    if(redirector) clearTimeout(redirector)
  },
  mounted(){
    if(this.$route.query && this.$route.query.reason){
      this.color = 'red'
      switch(this.$route.query.reason){
        case "BLUR_TIME_OUT":
          this.reason = "The test was submitted due to long time inactivity in website."
          break;
        case "BLUR_ALLOWED_COUNT":
          this.reason = "The test was submitted due to suspicious activity."
          break;
        case "TEST_TIME_OUT":
          this.color = 'green'
          this.reason = "The test was auto submitted due to time out."
          break;
        case "PROCTOR_TERMINATE":
          this.reason = 'The test was terminated by proctor.'
          break;
      }
    }
    this.$store.commit('set_test_status', {tid: this.$route.query.tid})
    this.$store.dispatch('pingFirebase', {s: true, t: this.reason})
    this.$store.dispatch('hangupVideoProctor')

    if(this.$route.query.redirect) {
      this.$store.commit('open_snackbar',{text: `Redirecting in 5 seconds`, color: 'blue'})
      redirector = setTimeout(() => {
        window.location.href = this.$route.query.redirect
      }, 5000)
    }

  },
  computed: {
    ...mapGetters(['user']),
    actionText(){
      return this.user && this.$route.query.pdf=='true'?'Upload PDFs':'Back to Home'
    },
    actionUrl(){
      return this.user && this.$route.query.pdf=='true'?'/test':'/'
    }
  }
}
</script>