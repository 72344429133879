<template>
  <v-dialog v-model="show" fullscreen  style="z-index:208">
    <v-card >
      <v-card-text class="text-xs-center">
        <h3  class="headline mb-1">
            <!--Take picture of yourself or/with ID Card*-->
            <br v-if="!isDesktop">
            ( <span v-html="timeLeft"></span> )
        </h3>
        <v-divider></v-divider>

        <div v-if="zoomImage">
             <v-img
                contain
                :src="zoomImage"
                :lazy-src="zoomImage"
                :aspect-ratio="1"
                class="grey lighten-2 mb-1"
                style="max-height: 70vh"></v-img>
            <v-btn @click="zoomImage=null" outline round>Close</v-btn>
        </div>

        <v-layout row wrap v-if="uploadState==0" class="mt-2" v-show="!zoomImage">
          <v-flex xs12 md3  >
            <v-card hover class="pa-4" color="primary" dark>
              <span>Upload Image</span>
              <br><br>
              <!-- <img src="../../assets/test/focus.png"  style="width:200px" /> -->
              <v-btn @click="openCamera()" outline round v-if="isDesktop">Take Snapshot</v-btn>
              <!-- <br>
              <div>- Or -</div> -->
              <v-btn @click="$refs.file.click()" outline primary round v-if="pdfUpload">Select Files</v-btn>
              <input type="file" ref="file" style="visibility: hidden; width:0; height:0" multiple @change="fileSelected" accept=".pdf">
              <!-- ,.png,.jpg,.jpeg,.PNG,.JPG,.JPEG,.webp,.WEBP -->
            </v-card>
          </v-flex>
          <v-flex xs12 md1 ></v-flex>
          <v-flex xs12 md6>
        <!--<v-alert
            :value="uploadedImages.length==0"
            color="info"
            outline
            icon="info"
            class="mt-4"
            >
            Upload ID with your photo if required by institute.
            </v-alert>-->
          <v-layout row wrap v-if="uploadedImages.length">
            <v-flex
              v-for="(image,i) in uploadedImages"
              :key="i"
              xs12
              md4
              class="pa-1"
            >
              <v-card  tile>
                <v-img
                  :src="`${user.config.CDN}test-files/${image}`"
                  height="150px"
                  contain
                ></v-img>
                <v-card-actions>
                    <v-btn @click="zoomImage = `${user.config.CDN}test-files/${image}`" round outline >Zoom</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteOverAllFile(i,'image')" round outline color="red">Delete</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>

           <v-layout row wrap v-if="uploadedPdfs.length">
            <v-flex
              v-for="(pdf,i) in uploadedPdfs"
              :key="i"
              xs6
              md4
              class="pa-1">
              <v-card tile>
                <v-card-actions>
                    <v-btn :href="`${user.config.CDN}test-files/${pdf}`" target="_blank" round outline >View PDF {{i+1}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteOverAllFile(i,'pdf')" round outline color="red">Delete</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        </v-layout>

        <v-layout row wrap v-if="uploadState>=1">
          <v-flex xs12 v-show="uploadState==1">
            
            <div v-if="!cameraError">
               <div class="mt-2 mb-1">Take picture of your answer sheet</div>
               <video autoplay="true" id="videoElement" ref="videoElement"></video>
                <br/>
                <v-btn btnprimary @click="takepicture()" color="primary" round>Capture</v-btn>
                <v-btn btnprimary @click="uploadState=0" color="secondary" outline round>Cancel</v-btn>
            </div>
            <div v-else>
                <p class="pt-4 mb-1"> Please allow website to access webcam!</p>
                <img class="pt-4 pb- mb-1" src="./../../assets/video-permission.png" alt="Allow permission">
                <v-btn outline @click="allow()">Recheck</v-btn>
                <v-btn btnprimary @click="uploadState=0" outline>Back</v-btn>
            </div>
            <v-select
                v-if="devices.length>1 && !uploading"
                :items="devices"
                v-model="videoInput"
                item-text="label"
                item-value="deviceId"
                label="Select Camera Device"
                outline
                @change="startWebCam()"
                style="max-width:350px; margin: 0 auto"
            ></v-select>
            <br >
           </v-flex>

          <v-layout v-show="uploadState==2" class="text-xs-center mt-2">
            <v-flex xs12>
                <p>Please make sure your handwriting is visible.</p>
                <canvas id="canvas"></canvas>
                <v-progress-linear :indeterminate="true" v-if="uploading"></v-progress-linear>
                <div>
                  <v-btn @click="uploadBase64()" :disabled="uploading" color="primary" round>Upload</v-btn>
                  <v-btn @click="clearCapture()" :disabled="uploading" color="secondary" round>Retake/Cancel</v-btn>
                </div>
                  <div class="output" style="visibility:hidden;width: 0;height: 0;position:absolute;top:0">
                  <img id="photo" alt="The screen capture will appear in this box.">
                </div>
            </v-flex>
            
        </v-layout>

        </v-layout>

        <v-layout row wrap v-if="uploadState<=-1">
          <v-layout row wrap class="mt-2">
            <v-flex xs12 class="mt-4">
                <p class="text-xs-center">Uploading please wait...</p>
                <v-progress-linear :indeterminate="true" v-if="uploading"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-layout>

      </v-card-text>
      <v-card-actions class="mt-4">
        <template v-if="uploadState==0">
          <v-btn color="green darken-1" outline flat @click="$emit('closeDialog')"  round>Resume</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="orange" dark  @click="$emit('showSubmitDialog')"  round>Submit</v-btn>
        </template>
      </v-card-actions>
    </v-card>
   <input style="width:0; height:0; visibility: hidden;position: absolute;top: 0;" type="file" accept="image/*" capture="environment" @change="mobileCamCaptured" ref="mobileCapture" @click="internalCameraClicked()"/>
  </v-dialog>
</template>

<script>
import {instance as axios} from './../../_axios'
import ReleaseMedia from './../../utils/releaseMedia'
import Compressor from 'compressorjs'
import {mapGetters} from 'vuex'
var gumStream;

axios.timeout = 0

export default {
    data(){
        return {
            uploadState: 0,
            cameraError: false,
            video:  null,
            canvas: null,
            width:  640,
            height: 360,
            devices: [],
            videoInput: null,
            uploading: false,
            base64: null,
            uploadedImages: [],
            selectedImages: [],
            selectedFiles: [],
            uploadedPdfs: [],
            zoomImage: false,
            APPURL: process.env.VUE_APP_URL,
            isDesktop: window.innerWidth>500
        }
    },
    computed:{
        ...mapGetters(['user', 'timeLeft'])
    },
    mounted(){
        this.uploadState = 0
        this.cameraError = null
        this.uploadedImages = this.testImages.concat([])
        this.uploadedPdfs = this.pdfFiles || []
        this.getCameraSelection()
        if(window.innerWidth<480){
            this.width = window.innerWidth-30
            this.height = window.innerWidth*4/3
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        testId: {
            type: String,
            default: ''
        },
        testImages: {
            type: Array,
            default: function(){ return [] }
        },
        pdfFiles: {
            type: Array,
            default: function(){ return [] }
        },
        // timeLeft: {
        //     type: String,
        //     default: function() {return "00:00:00"}
        // },
        stream: {
            type: MediaStream,
            default: () => null
        },
        pdfUpload: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        openCamera(){
            this.uploadState=1
            if(!this.isDesktop) this.$refs.mobileCapture.click()
        },
        fileSelected(event){
            this.selectedFiles = Array.from(this.$refs.file.files)
            this.uploadState = -1
            this.uploadBulk()
        },
        removeImage(index){
            this.selectedImages.splice(index,1)
            if(this.selectedImages.length<1){
                this.uploadState = 0
            }
        },
        allow(){
            this.$nextTick(() => {
                this.startWebCam()
            })
        },
        async getCameraSelection  ()  {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            this.devices = videoDevices.map(videoDevice => {
                return { deviceId: videoDevice.deviceId, label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) }
            });
            // if(videoDevices.length>1) this.devices = [{deviceId: 'user', label: 'Front'}, {deviceId: 'environment', label: 'Rear'}]
        },
        startWebCam(){
           this.video = document.getElementById('videoElement')
            if(this.stream) {
                gumStream = this.stream
                return this.onGettingMediaStream()
            }

            ReleaseMedia(gumStream)
            this.cameraError = null
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ 
                    video: {
                        width: 640,
                        height: 480,
                        deviceId: this.videoInput
                    }
                })
                .then( (stream) => {
                    gumStream = stream
                    this.onGettingMediaStream()
                })
                .catch( (err0r) => {
                    if(/permission/.test(err0r.message.toLowerCase())){
                       this.cameraError = "Please allow this website to access webcam!"
                    } else {
                        this.cameraError = err0r.message.replace(/[^\:]*:/, '')
                    }
                });
            }
        },
        onGettingMediaStream(){
            this.video.srcObject = gumStream;
            this.video.play()
            this.video.addEventListener('canplay', (ev) => {
                this.height = this.video.videoHeight / (this.video.videoWidth/this.width);
            })
        },
        takepicture() {
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            if (this.width && this.height) {
                this.uploadState = 2
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(this.video, 0, 0, this.width, this.height);
                
                var data = canvas.toDataURL('image/png');
                photo.setAttribute('src', data);
                this.base64 = data
            } else {
                this.clearphoto();
            }
        },
        clearphoto() {
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);
            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
        },
        clearCapture(){
            this.clearphoto()
            this.uploadState = 1
        },
        uploadFile(){},
        uploadBase64(){
            this.uploading = true
            var qn = this.$route.query.qn || 1

            document.getElementById('canvas').toBlob((blob => {
                    var fd = new FormData();
                    var testID = this.$route.params.id
                    
                    fd.append('test_id', String(testID));
                    fd.append('data', blob)
                    axios.post(`/fileUploadOverall/${testID}/${qn}/png`, fd)
                    .then(resp => {
                        // this is different 
                        this.uploadState = 0
                        this.uploadedImages = resp.data.newData //this.uploadedImages.concat(resp.data.newData)
                        this.$emit('imageUploaded', resp.data.newData)
                        return { success: true, newData: resp.data.newData }
                    })
                    .catch(error => {
                        console.log(error)
                        return { success: false, error }
                    })
                    .then(() => {
                        this.uploading = false
                    })
            }), 'image/webp')

                 
        },
        uploadBulk(){
            this.uploading = true

            var promises = this.selectedFiles.map((file) => {
                var qn = this.$route.query.qn || 1
                var fd = new FormData()
                fd.append('test_id', String(this.$route.params.id));
                fd.append('data', file)
               return axios.post(`/fileUploadOverall/${this.$route.params.id}/${qn}/pdf`, fd)
               .then(resp => {
                   return { success: true, newData: resp.data.newData }
               })
               .catch(error => {
                   console.log(error)
                   return { success: false, error }
               })
            })
            
            Promise.all(promises)
            .then(values => {
                var files = values.filter((val, index) => val.success == true).map(aval => aval.newData).flat()
                if(this.selectedFiles[0].name.endsWith('pdf')){
                    this.uploadedPdfs = Array.from(new Set(files))
                    this.$emit('pdfUploaded', this.uploadedPdfs)
                } else {
                    this.uploadedImages = Array.from(new Set(files))
                    this.$emit('imageUploaded', this.uploadedImages)
                }
                this.selectedFiles = this.selectedFiles.filter((val, index) => values[index].success == false)
                this.uploading = false
                // if(this.selectedFiles.length==0) this.uploadState = 0
            })
            .catch(error => {
                this.uploading = false
                this.$store.commit('open_snackbar', {text: 'Upload failed! Please try again', color: 'red'}) 
                console.log(error)
            })
            .then(() => {
                this.uploadState = 0
            })
            
        },
        closeCamera(){
           ReleaseMedia(gumStream)
        },
        deleteOverAllFile(i, type){
            this.uploading   = true
            this.uploadState = -1
            var file_name    = type=='image'?this.uploadedImages[i]:this.uploadedPdfs[i]
            axios.post('/deleteOverAllFile', {test_id: this.testId, file_name})
           .then(resp => {
                this.$store.commit('open_snackbar', {text: 'File deleted!', color: 'green'}) 
                if(type=='image') {
                    this.uploadedImages.splice(i,1)
                    this.$emit('imageUploaded', this.uploadedImages)
                }
                else {
                    this.uploadedPdfs.splice(i,1)
                    this.$emit('pdfUploaded', this.uploadedPdfs)
                }
            })
           .catch(error => {
                this.$store.commit('open_snackbar', {text: 'Delete failed! Please try again', color: 'red'}) 
                console.log(error)
           })
           .then(() => {
                this.uploading = false
                this.uploadState = 0
           })
        },
        mobileCamCaptured(event){
            
            console.log(event)
            this.uploading = true
            let files = event.target.files
            if(files.length<1) return 

            this.uploadState = 2
            let fileReader   = new FileReader()
            fileReader.onload = (prog) => {
                var image = new Image()
                image.onload = () => {
                    var canvas  = document.getElementById('canvas')
                    var context = canvas.getContext('2d')
                    context.drawImage(image, 0, 0, this.width, this.height)
                }
                image.src = prog.target.result
                console.log(prog)
            }

            fileReader.readAsDataURL(files[0])
            
            var fd = new FormData();
            var qn = this.$route.query.qn || 1
            var testID = this.$route.params.id
            fd.append('test_id', String(this.$route.params.id));
            let that = this
             
            new Compressor(files[0], {
                quality: 0.8,
                maxWidth: 1080,
                maxHeight: 720,
                success(result) {
                    fd.append('data', result)
                    axios.post(`/fileUploadOverall/${testID}/${qn}/png`, fd)
                    .then(resp => {
                        that.uploadState = 0
                        that.uploadedImages = resp.data.newData //that.uploadedImages.concat(resp.data.newData)
                        that.$emit('imageUploaded', resp.data.newData)
                        return { success: true, newData: resp.data.newData }
                    })
                    .catch(error => {
                        that.uploadState = 0
                        return { success: false, error }
                    })
                    .then(() => {
                        that.uploading = false
                    })
                },
                error(err) {
                    console.log(err.message);
                },
            });
            
        },
        internalCameraClicked(){
            if(window.api) {
                try{
                    window.api.executeCommand('hangup');
                    window.api = null
                } catch(e){
                    console.log(e)
                }
            }
            console.log('Proctor/Timer removed')
            this.$store.commit('toggle_proctor', false)
        },
        toggleProctor(){
            console.log('toggle')
            this.$emit('toggleProctor')
        }
    },
    watch:{
        uploadState(val, oldval){
            if(val==0){
                this.cameraError = null
            } else if(val==1)
            this.$nextTick(() => {
                this.startWebCam()
            })
        }
    }
}
</script>

<style>
#videoElement {
	max-width: 100%;
	height: auto;
	background-color: #666;
}
</style>