const { instance } = require('./../../../_axios.js');
import SyncHelper from './SyncHelper'
import submitCount from './../../../utils/TestQuestionAttemptCounts'
import LoadingHelper from './LoadingHelper'

const submitPath = process.env.VUE_APP_SUBMIT_API || '/test/submit'

const Submit = (context, reason) => {

    clearInterval(context.updateIntervalID) 
    LoadingHelper(context, true, 'Submitting Test. Please Wait ..')
    context.submitdialog = false
    instance.post(submitPath, {id: context.userData._id, updates: SyncHelper.getLocalUpdates(context), terminated: reason})
    .then(async data => { 
      setTimeout(() => {
        window.location.href = '//'+window.location.host+`/test-submitted?${reason?'reason='+reason:''}${context.testData.PDF_UPLOAD?`&pdf=true`:``}&tid=${context.testData._id}&uid=${context.user.id}&redirect=${context.testConfig.SUBMIT_REDIRECT? context.testConfig.SUBMIT_REDIRECT:''}`
      }, 1000)
    })
    .catch(err =>   {
      console.log(err)
      alert('Test Submit Failed : '+err.message)
      // context.$router.replace({ path: '/test-submitted', query: {} }) 
    })
    .then(() =>  {  context.overlayLoading = false })
}

// const ShowSubmitDialog = (context) => {
//   context.submitdialog = true
//   context.submit_overview = submitCount(context.questions)
// }

const ShowPreSubmitDialog = (context) => {
  if(context.testConfig.HIDE_PRE_SUBMIT){ context.showSubmitDialog() } 
  else { context.uploadDialog = true }
}

export default {
  // ShowSubmitDialog, 
  ShowPreSubmitDialog, 
  Submit
}