<template>
<div>
  <test-toolbar 
    :error="error" 
    :test-name="testData?testData.name:''">
  </test-toolbar>
  
    <!-- @showupload="showUploadDialog()" -->
  <div v-if="!error">

    <v-dialog v-model="showPreview" fullscreen style="z-index:202" scrollable>
      <test-preview v-if="showPreview" :questions="questions" @close="showPreview=false"></test-preview>
    </v-dialog>
    
    <attachment-dialog :testData="testData" :showAttachments="showAttachments" @hide="showAttachments=false"></attachment-dialog>

    <v-layout :class="$vuetify.breakpoint.mdAndUp?'row':'column'" class="mathcontent min80vh">
      <v-flex xs12 :class="{'large-text':largeText}">
        <div v-if="question">
          <question-item-pdf 
            ref="activeQuestionItem"
            :_question="question" 
            :key="question.id" 
            :stream="localStream?localStream.stream:null"
            :showAttachmentButton="testData && Array.isArray(testData.attachments) && testData.attachments.length>0"
            :showAttachments="showAttachments"
            @showAttachmentDialog="showAttachments=true"
            @showAttachmentsDialog="showAttachments=true"
            @initStream="!isDesktop?initStream(() => {}):null"
            ></question-item-pdf>
            <!-- 
              
              :readonly="!!testConfig.ONLY_PDF_ANSWER"
              :showDownload="testConfig.QUESTION_PDF_DOWNLOAD==true"
              @answerDeleted="answerDeleted"
              @answer="setAnswer"
              :time-left-html="timeLeftHTML" 
              :submitdialog="submitdialog"
              :show-audio-recording="testConfig.AUDIO_RECORDING"
              :show-image-capture="testConfig.IMAGE_CAPTURE"
              -->

        </div>
      </v-flex>

    </v-layout>


<chat 
v-if="!testConfig.DISABLE_CHAT && testData" 
:test-mockid="testData._id" 
:test-name="testData.name" 
@extendTest="extendTest"
@startProctor="startProctor( initStream , true)"
@initStream="initStream(() => {})"></chat>


<!-- LOADING PROGRESS -->
  <overlay-loading :loading="overlayLoading" :message="overlayMessage"></overlay-loading>

<!-- SUBMIT DIALOG -->
  <submit-dialog @submitTest="submitTest()" :hide-count="true"></submit-dialog>
<!-- :submit-overview="submit_overview" 
    :time-left="formatTimeLeft" 
    :submitdialog="submitdialog"
    @closeSubmitDialog="submitdialog=false" -->
<!-- UPLOAD DIALOG -->
  <upload-dialog 
    v-if="userData"
    :stream="localStream?localStream.stream:null"
    :show="uploadDialog" :test-id="userData._id"
    :test-images="userData.iu || []" 
    :pdf-files="userData.pu || []" 
    :pdf-upload="testConfig.PDF_UPLOAD"
    @imageUploaded="imageUploaded"
    @pdfUploaded="pdfUploaded"
    @closeDialog="uploadDialog=false"
    @showSubmitDialog="showSubmitDialog()">
  </upload-dialog>

  <v-btn
    v-if="isDesktop"
    style="position:fixed; bottom: 10px; right: 0"
    dark
    fab
    right
    color="grey"
    @click="toggleChatPanel()"
  >
    <v-icon>chat</v-icon>
  </v-btn>

<!-- WARNING DIALOG -->
  <warning-dialog 
   :show-warning-dialog="showWarningDialog" 
    @hidewarning="showWarning=false;showWarningTimer=false" 
    :blur-time="String(blurTime)" 
    :show-warning-timer="showWarningTimer && testConfig.AUTO_TERMINATE">></warning-dialog>
  <!-- 
    ALREADY LOADED
    <multiple-faces 
    v-if="testConfig.DETECT_MULTIFACE && testConfig.PROCTOR_TYPE!=PTYPES.VANIS" 
    @multiFaces="notifyMultiFaces" 
    @modelLoading="setModelLoading" 
    :submitted="submitted"
    :normal='testConfig.DETECT_FACE_NINTERVAL'
    :gap='testConfig.DETECT_FACE_GINTERVAL'></multiple-faces> 
    -->
  
  </div>
  <div v-else class="text-xs-center"> 
    <h3 class="display-2 pt-4">{{error}}</h3> 
    <v-btn @click="reloadTest()"><v-icon>refresh</v-icon> Retry</v-btn>
  </div>
  <detect-speech v-if="testData &&testConfig.DETECT_SPEECH" :mock-id="testData._id"></detect-speech>

</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import Chat from './../../components/ChatOverlay.vue'

import DetectSpeech from './../../components/DetectSpeech.vue'
// import MultipleFaces from './MultipleFaceDetector.vue'

import TestToolbar from './TestToolbarPdf';
import QuestionItemPdf from './QuestionItemPdf';
import WarningDialog from './Warning.vue';
import SubmitDialog from './SubmitDialog'
import UploadDialog from './UploadDialog.vue'
import TestPreview from './TestPreview.vue';

// import MobileQNavigator from './MobileQNavigator.vue';
import OverlayLoading from './OverlayLoading.vue'

import { Duration } from "luxon";

import {closeFullscreen} from './../../utils/fullscreen.js'

// import TIMELEFTFORMAT from './utils/TimeLeftFormat.js'
import FireLog from './utils/Firelog'

import testConfig from './utils/readEnv'
import ProctorHelper from './utils/VideoProctor'
import SubmitHelper from './utils/SubmitHelper'
// import SyncHelper from './utils/SyncHelper'
import ExtendHelper from './utils/ExtendHelper'
// import NavigateHelper from './utils/NavigateHelper'
import MountDestroyHelper from './utils/MountDestroyHelper'
import {NotifyFaceCount} from './utils/DetectFace'
import PreUploadHelper from './utils/PreUploadHelper'
import LoadingHelper from './utils/LoadingHelper'
import AttachmentDialog from './AttachmentDialog.vue';

window.api ;
export default {
  name: 'TestInside',
	data() {
	  return {
      qn: this.$route.query.qn?parseInt(this.$route.query.qn) : 1,
      totalQuestions: 0,
      testData: null,
      userData: null,
      instructions: null,
      loading: false,
      overlayLoading: true,
      overlayMessage: '',
      qNavDialog: false,
      showSectionInstruction: false,
      fab: -1,
      syncing: false,
      submitdialog: false,
      uploadDialog: false,
      timeLeft: 9999999,
      duration: 9999999,
      error: null,
      localUpdate: {},
      faceCount: 1,
      updateIntervalID: null,
      submit_overview: {},
      notifyUploadSheet: false,
      
      blurCount: parseInt(sessionStorage.getItem('blurCount')) || 0,
      isBlur: false,
      isFullscreen: false,

      submitted: false,
      
      blurTime: testConfig.MAX_BLUR_ALLOWED_TIME,
      warningOffset: testConfig.WARNING_OFFSET,
      showAttachments: false,
      showWarning: false,
      showWarningTimer: false,
      showPreview: false,
      localStream: null,
      denied: {
        screen: false,
        webcam: false
      },
      askingPermission: false,
      permissionMessage: 'Checking webcam and audio permission status...',

      largeText: localStorage.getItem('large_text')==='true',
      timeStart: null,
      timeEnd: null,
      mediaDevices: [],
      mouseOut: false,
      testConfig,
      lastImageProctor: null,
      isDesktop: window.innerWidth > 500,
      PTYPES: ProctorHelper.PROCTOR_TYPES
    }
  },
  mounted(){        MountDestroyHelper.onMount(this) },
  beforeDestroy(){  MountDestroyHelper.beforeDestroy(this)},
  methods:{
    ...mapActions(['initJitsiProctor', 'updateQuestion', 'setJitsiOptions', 'increaseTimeSpent', 'saveLog', 'hangupVideoProctor', 'toggleChatPanel']),
    initStream(cb = ()=>{}) {               ProctorHelper.initStream(this, cb)},
    startProctor(cb = ()=>{}, now = false){ ProctorHelper.startProctor(this, cb, now) },
    extendTest(minutes){      ExtendHelper(this, minutes)   },
    // saveAndNext(){            SyncHelper.SaveAndNext(this)  },
    // markQ() {                 SyncHelper.MarkQ(this)        },
    // clearQR() {               SyncHelper.ClearQ(this)       },
    // setAnswer({qn, answer}){  SyncHelper.SetAnswer(this, {qn, answer}) },
    // answerDeleted(data){      SyncHelper.DeleteAnswer(this, data)      },
    // prev() {                  NavigateHelper.prev(this) },
    // next() {                  NavigateHelper.next(this) },
    // selectQuestion(data){     NavigateHelper.selectQuestion(this, data) },
    // showSubmitDialog(){       SubmitHelper.ShowSubmitDialog(this) },
    submitTest(reason){       SubmitHelper.Submit(this, reason)   },
    // showUploadDialog(){       SubmitHelper.ShowPreSubmitDialog(this)},
    // toggleTextSize(){         this.largeText = !this.largeText },
    // answer sheet upload before final test
    imageUploaded(data){      PreUploadHelper.ImageUploaded(this, data) },
    pdfUploaded(data){        PreUploadHelper.PdfUploaded(this, data)   },
    setModelLoading(val){     LoadingHelper(this, val, 'Initiating AI video detector ..') },
    notifyMultiFaces(nof = 1){
      // required for jitsi
      this.faceCount = nof
      NotifyFaceCount(this, nof)
    },
    reloadTest(){
      window.location.reload()
    }
	},
  
  computed: {
    ...mapGetters([ 'inTest', 'user', 'pauseProctor', 'questions', 'firebaseApp', 'jitsiLoaded']),
    question(){
      if(this.questions.length<1) return null
      return this.questions[this.qn-1]
    },
    
    testURL() {
      return '/test/in/'+this.$route.params.id+"?ts="+Date.now()
    },
    formatTimeLeft() {
      return Duration.fromObject({hours: 0, minutes: 0, seconds: this.timeLeft}).normalize().values
    },
    formatTimeLeftPart() {
      return 0
    },
    // showBottomNav(){
    //   return !this.submitdialog && !this.overlayLoading
    // },
    showWarningDialog(){
      return this.showWarning && this.warningOffset<=0 // && this.testConfig.DETECT_FULLSCREEN
    },
    // timeLeftHTML(){
    //   return TIMELEFTFORMAT(this.formatTimeLeft)
    // },
  },
  components: {
    'question-item-pdf': QuestionItemPdf,
    'submit-dialog': SubmitDialog,
    'upload-dialog': UploadDialog,
    'chat': Chat,
    'test-toolbar': TestToolbar,
    'warning-dialog': WarningDialog,
    'test-preview': TestPreview,
    // 'multiple-faces': MultipleFaces,
    'detect-speech': DetectSpeech,
    'overlay-loading': OverlayLoading,
    'attachment-dialog': AttachmentDialog
  },
  watch: {
    'inTest'(val) {
      if(val) { }
      else closeFullscreen()
    },
    'pauseProctor'(val) {
      FireLog(this, val?'IMAGE_CAPTURE':'IMAGE_CAPTURE_RESUME')
    }
  }
}
</script>
<style >
.large-text{
  font-size: 200% !important;
}
.min80vh{
  min-height: 80vh;
}
.test-logo{
	padding: 4px;
	margin: 5px 20px;
}
#mobile-toolbar>.v-toolbar__content{
  justify-content:space-between
}
.timer-icon i{
  margin-bottom: -4px;
}
.table-bordered{
  border-collapse: collapse;
}
.table-bordered thead{
  padding: 5px 0
}
.table-bordered  td{
  border:1px solid #eee;
  padding: 5px;
}

.splitpanes__splitter{
  border-width: 1px 0 1px 0 !important;
  border-style: solid !important;
  border-color: black !important;
  height: 20px !important;
  text-align: center;
  background: antiquewhite;
}

.splitpanes__splitter::before{
  content: '↑ Scroll up to see uploaded image answers ↑';
}
.container{
  padding: 0 !important;
}
</style>